var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "js-common-wrap video-search-root" }, [
    _c("div", { staticClass: "js-common-head" }, [
      _c("label", { staticClass: "label" }, [_vm._v("视频搜索")]),
      _c(
        "div",
        { staticClass: "search-root" },
        [
          _c(
            "el-input",
            {
              attrs: { placeholder: "请输入需要搜索的标签" },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.getList.apply(null, arguments)
                },
              },
              model: {
                value: _vm.keywords,
                callback: function ($$v) {
                  _vm.keywords = $$v
                },
                expression: "keywords",
              },
            },
            [
              _c("el-button", {
                attrs: {
                  slot: "append",
                  size: "large",
                  icon: "el-icon-search",
                },
                on: { click: _vm.getList },
                slot: "append",
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { ref: "detailsContent", staticClass: "js-common-content" },
      [
        _c(
          "ul",
          { staticClass: "video-list-root" },
          _vm._l(_vm.list, function (item, key) {
            return _c(
              "li",
              { key: key, staticClass: "item" },
              [
                _c("Xgplayer", {
                  staticStyle: { width: "100%" },
                  attrs: {
                    config: {
                      id: "watermelon" + key,
                      url: item.fullVideoUrl,
                    },
                  },
                  on: {
                    player: function ($event) {
                      _vm.Player = $event
                    },
                  },
                }),
                _c("h2", [_vm._v(_vm._s(item.tag))]),
              ],
              1
            )
          }),
          0
        ),
        !_vm.list.length
          ? _c("el-empty", {
              attrs: { description: "暂无数据，尝试更换搜索内容试试" },
            })
          : _vm._e(),
        _c("common-pagination", {
          on: { curPageChange: _vm.handleCurPageChange },
          model: {
            value: _vm.pageOptions,
            callback: function ($$v) {
              _vm.pageOptions = $$v
            },
            expression: "pageOptions",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <VideoTagsManage type="edit"/>
</template>

<script>
import VideoTagsManage from "@/components/AddTags/VideoTagsManage.vue";

export default {
  components: { VideoTagsManage }, // edit 编辑状态为角色权限设置；select  选择状态，不能添加编辑
};
</script>

<style lang="scss">

</style>

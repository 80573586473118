<template>
  <el-dialog class="js-dialog-form" :title="isModify?'修改角色':'添加角色'" :visible.sync="dialogVisible" :close-on-click-modal="false" width="818px">
    <ul>
      <!-- <li class="label common-tip"><i>*</i>角色名称</li> -->
      <li>
        <div class="label common-tip"><i>*</i>角色名称</div>
        <el-input v-model="params.name" placeholder="请输入角色名称" size="small" required></el-input>
      </li>
      <!-- <li class="label common-tip"><i>*</i>角色描述</li> -->
      <li>
        <div class="label common-tip"><i>*</i>角色描述</div>
        <el-input v-model="params.description" type="textarea" :rows="4" placeholder="请输入角色描述" size="small" required></el-input>
      </li>
    </ul>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel" size="small">取 消</el-button>
      <el-button type="primary" @click="handleConfirm" size="small">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: '',
  props: ['value','isModify','currentItem'],
  data() {
    return {}
  },
  computed: {
    dialogVisible: {
      get(){
        return this.value
      },
      set(newVal){
        this.$emit('input', newVal)
      }
    },
    params(){
      return this.currentItem
    }
  },
  mounted() {},
  methods: {
    handleCancel(){
      this.dialogVisible = false
    },
    handleConfirm(){
      this.$emit('handleItem',this.params)
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
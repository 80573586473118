<template>
  <div class="js-common-wrap video-search-root">
    <div class="js-common-head">
      <label class="label">视频搜索</label>
      <div class="search-root">
        <el-input
          placeholder="请输入需要搜索的标签"
          v-model="keywords"
          @keyup.enter.native="getList"
        >
          <el-button
            size="large"
            slot="append"
            icon="el-icon-search"
            @click="getList"
          ></el-button>
        </el-input>
      </div>
    </div>
    <div class="js-common-content" ref="detailsContent">
      <ul class="video-list-root">
        <li class="item" v-for="(item, key) in list" :key="key">
          <Xgplayer
          style="width:100%"
            :config="{
              id: 'watermelon' + key,
              url: item.fullVideoUrl,
            }"
            @player="Player = $event"
          />
          <h2>{{ item.tag }}</h2>
        </li>
      </ul>
      <el-empty
        v-if="!list.length"
        description="暂无数据，尝试更换搜索内容试试"
      ></el-empty>
      <common-pagination
        v-model="pageOptions"
        @curPageChange="handleCurPageChange"
      ></common-pagination>
    </div>
  </div>
</template>

<script>
import CommonPagination from "@/components/CommonPagination";
import Xgplayer from "xgplayer-vue";

export default {
  components: {
    CommonPagination,
    Xgplayer,
  },
  data() {
    return {
      keywords: "",
      pageOptions: {
        page: 1,
        perPage: 20,
        total: 0,
      },
      list: [],
    };
  },
  mounted() {
    // this.getList();
  },
  methods: {
    handleCurPageChange() {
      this.getList();
    },
    getList() {
      this.$axios({
        method: "get",
        url: "api/csp/mgr/v1/tag/page",
        params: {
          tag: this.keywords,
          page: this.pageOptions.page,
          perPage: this.pageOptions.perPage,
        },
      }).then(({ data }) => {
        console.log(data);
        if (!data.status) {
          this.list = data.data.rows;
          this.pageOptions.total = data.data.total;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.js-common-content {
  background: #fff;
}
.el-select .el-input {
  width: 130px;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.video-list-root {
  display: flex;
  flex-wrap: wrap;
  padding: 30px;
  .item {
    flex: 1 50%;
    max-width: 50%;
    padding: 0 8px;
    margin-bottom: 40px;
    h2 {
      margin-top: 10px;
      color: #18191c;
    }
    &>div{
        width: 100%!important;
    }
  }
}
</style>
